import 'core-js/stable';
import 'regenerator-runtime/runtime';

import './vars.scss';

import 'rc-tooltip/assets/bootstrap_white.css';

Promise.all([
  import(/* webpackChunkName: 'legacy-m' */ 'jquery'),
  import(/* webpackChunkName: 'legacy-ui' */ 'jquery-ui'),
]).then((jq) => {
  const [$] = jq.map((m) => m.default);

  window.$ = $;
  window.jQuery = $;
});

Promise.all([
  import(/* webpackChunkName: 'ls' */ './utils/LocalStorage'),
  import(/* webpackChunkName: 'cl' */ './utils/Collections'),
  import(/* webpackChunkName: 'rh' */ './utils/RHService'),
  import(/* webpackChunkName: 'is' */ './utils/IconService'),
  import(/* webpackChunkName: 'lg' */ './utils/Logger'),
  import(/* webpackChunkName: 'mt' */ './utils/Metrics'),
]).then((serviceList) => {
  const [ls, cl, rh, is, lg, mt] = serviceList.map((m) => m.default);

  lg.init();

  ls.remove('rh-app-dancers');

  ls.init('rh-board-grid', null, rh.getGridLayouts());

  ls.init(
    'rh-settings',
    (settings) => {
      return rh.checkAppSettings(settings);
    },
    rh.getDefaultAppSettings()
  );

  if (!ls.get('rh-ab-group')) {
    const abGroup = Math.floor(Math.random() * 101);

    ls.set('rh-ab-group', abGroup);
  }

  if (!ls.get('rh-experiments')) {
    ls.set('rh-experiments', false);
  }

  if (!ls.get('rh-client-id')) {
    const rhClientId = rh.getRandomID();

    ls.set('rh-client-id', rhClientId);
  }

  if (!ls.get('rh-stories-seen')) {
    ls.set('rh-stories-seen', []);
  }

  if (cl.isEqual(ls.get('rh-app-multiplayer'), [[92, 118]])) {
    ls.set('rh-app-multiplayer', null);
  }

  window.addEventListener('error', (e) => {
    if (
      e.message ===
      'ResizeObserver loop completed with undelivered notifications.'
    ) {
      const resizeObserverErrDiv = document.getElementById(
        'webpack-dev-server-client-overlay-div'
      );
      const resizeObserverErr = document.getElementById(
        'webpack-dev-server-client-overlay'
      );
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none');
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
    }
  });

  is.init();

  mt.visitParams();
});

import(/* webpackChunkName: 'app' */ './app');
